@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~video-react/dist/video-react.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

$font-xsssss: 10px;
$font-xssss: 12px;
$font-xsss: 14px;
$font-xss: 16px;
$font-xs: 18px;
$font-sm: 20px;
$font-md: 22px;
$font-lg: 25px;
$font-xl: 28px;
$font-xxl: 32px;

/* CUSTOM CSS HERE */
.main-content {
  height: 100% !important;
  padding-bottom: 56px;
  font-family: "Kanit", sans-serif !important;
}

body,
button,
input,
select,
textarea,
.open-font,
.fredoka-font {
  font-family: "Kanit", sans-serif !important;
}

/* MODEL */
.modal-backdrop {
  z-index: 39;
}
.modal-content {
  background: none;
}

/* LOGIN */
.login-card {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* LOGO */
.nav-header .nav-top a .logo-text {
  font-size: 30px !important;
  margin-left: 10px;
}
.nav-top .logo-text {
  color: #00a9a8 !important;
}
.web-icon {
  height: 72px;

  @media screen and (max-width: 991px) {
    height: 40px !important;
  }
}

/* APP FOOTER */
.app-footer {
  justify-content: space-around;
  background: #00a9a8 !important;
}

/* OTHER */

.form-group .style2-select {
  line-height: 40px;
  color: #212529 !important;
}

.border-bottom-lg {
  border-bottom: 3px solid #000;
}
.border-bottom-xs {
  border-bottom: 1px solid #f1f1f1;
}

.lottery-search {
  .col-xs-2 {
    width: 16.66666667%;
    @media (max-width: 768px) {
      padding: 0 5px;
    }
  }

  .col-xs-4 {
    width: 33.33333333%;
    @media (max-width: 768px) {
      padding: 10px 5px;
    }
  }

  .col-xs-8 {
    width: 66.66666667%;
  }

  .br-mb {
    display: none;
  }
  @media (max-width: 470px) {
    h2 {
      font-size: 36px !important;
    }
    .font-sm.text-warning {
      font-size: 16px !important;
    }
    .font-xsss.text-white {
    }
    .br-mb {
      display: block;
    }

    button.rounded-xxl {
      font-size: 14px !important;
    }
  }

  .text-warning {
    font-size: 18px !important;
  }

  .lottery-search-digits {
    padding: 5px 0;

    .lottery-digit label {
      display: none;
    }
    @media (max-width: 768px) {
      span {
        display: none;
      }
    }
  }

  .lottery-search-type {
    padding: 5px 0;
    button {
      display: block;
      width: 100%;
      border: none;
    }
  }

  .lottery-search-set {
    padding: 5px 0;
    button {
      display: block;
      width: 100%;
      border: none;
    }

    @media (max-width: 768px) {
      .lottery-search-set-label {
        padding: 5px 0;
      }
    }
  }

  .lottery-search-button {
    padding: 5px 0;
    .col-xs-8,
    .col-xs-4 {
      @media (max-width: 768px) {
        padding: 10px 5px;
      }
      @media (max-width: 440px) {
        padding: 5px;
      }
    }
    @media (max-width: 480px) {
      a.p-3 {
        padding: 0.5rem 0.2rem !important;
        span {
          display: none;
        }
      }
    }
  }

  .text-warning {
    color: #fec331 !important;
  }
}

.lottery-search-result {
  .lotto-set {
    top: 0;
    left: 0;
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding: 10px 15px !important;
    @media (max-width: 768px) {
      font-size: 1.3rem;
      line-height: 2.5rem;
      padding: 10px 10px !important;
    }
    @media (max-width: 450px) {
      font-size: 0.8rem;
      line-height: 1.5rem;
      padding: 5px 5px !important;
    }
  }
  .lotto-set-1 {
    background-color: darkgreen !important;
  }
  .lotto-set-2 {
    background-color: crimson !important;
  }
  .lotto-set-3 {
    background-color: dodgerblue !important;
  }
  .lotto-set-4 {
    background-color: darkred !important;
  }
  .lotto-set-5 {
    background-color: darkorchid !important;
  }
  .lotto-set-6 {
    background-color: orangered !important;
  }
  .lotto-set-7 {
    background-color: olivedrab !important;
  }
  .lotto-set-8 {
    background-color: slateblue !important;
  }
  .lotto-set-9 {
    background-color: darkgoldenrod !important;
  }
  .lotto-set-10 {
    background-color: darkslategrey !important;
  }
  .lotto-set-11 {
    background-color: firebrick !important;
  }
  .lotto-set-12 {
    background-color: khaki !important;
  }
  .lotto-set-13 {
    background-color: darksalmon !important;
  }
  .lotto-set-14 {
    background-color: chocolate !important;
  }
  .lotto-set-15 {
    background-color: deeppink !important;
  }
  .lotto-set-16 {
    background-color: gold !important;
  }

  .lotto-number {
    top: 1.5rem;
    left: 50%;
    color: black !important;
    font-size: $font-xxl;
    letter-spacing: 9px;
    line-height: 2rem;
    width: 50%;
    padding-left: 1.2rem;

    left: unset;
    right: 0;
    padding-left: unset;
    padding-right: 25px;
    width: 50%;
    text-align: justify;
    text-align-last: center;
    padding-left: 0.5rem;

    //TABLET
    @media (max-width: 1024px) {
      top: 1rem;
      font-size: $font-md;
      padding-left: 0.9rem;
    }

    //MOBILE L
    @media (max-width: 425px) {
      top: 0.7rem;
      left: unset;
      right: 0;
      padding-left: unset;
      padding-right: 25px;
      width: 50%;
      text-align: justify;
      text-align-last: center;
      font-size: 24px;
      padding-left: 0.5rem;
      letter-spacing: 5px;
    }

    //MOBILE M
    @media (max-width: 375px) {
      top: 0.7rem;
      left: unset;
      right: 0;
      padding-left: unset;
      padding-right: 25px;
      width: 50%;
      text-align: justify;
      text-align-last: center;
      font-size: 24px;
      padding-left: 0.5rem;
      letter-spacing: 5px;
    }

    //MOBILE S
    @media (max-width: 320px) {
      top: 0.5rem;
      left: unset;
      right: 0;
      padding-left: unset;
      padding-right: 25px;
      width: 50%;
      text-align: justify;
      text-align-last: center;
      font-size: 20px;
      padding-left: 0.5rem;
      letter-spacing: 5px;
    }
  }

  .lotto-Qr-top {
    top: 30px;
    left: 169px;
    width: 98px;
    height: 77px;
    font-size: 1.8rem;
    line-height: 4rem;
    padding: 10px 15px !important;
    @media (max-width: 768px) {
      font-size: 2.3rem;
      line-height: 2.5rem;
      left: 120px;
      top: 13 px;
      padding: 7px 18px 3px 7px !important;
    }
  }

  .img {
    width: 70%;
    height: 95%;
  }

  .lotto-Qr-bottom {
    top: 135px;
    left: 83px;
    width: 110px;
    height: 75px;
    font-size: 1.8rem;
    line-height: 4rem;
    padding: 10px 15px !important;
    @media (max-width: 768px) {
      font-size: 2.3rem;
      line-height: 0.5rem;
      left: 50px;
      top: 125px;
      width: 107px;
      height: 73px;
      padding: 3px 19px 13px 9px !important;
    }

    //TABLET
    @media (max-width: 1024px) {
      // top: 2.0rem;
      // font-size: $font-lg;
      // padding-left: 0.9rem;
    }

    //MOBILE L
    @media (max-width: 425px) {
    }

    //MOBILE M
    @media (max-width: 425px) {
    }

    //MOBILE S
    @media (max-width: 320px) {
      // top: 42px;
      // font-size: 14px;
      // letter-spacing: -0.1px;
      // font-weight: 600;
      // color: black;
      // background-color: #efefef;
      // padding: 3px 8px;
    }
  }

  .lotto-Qr-cart {
    top: 157px;
    left: 62px;
    width: 111px;
    height: 77px;
    font-size: 1.8rem;
    line-height: 4rem;
    padding: 10px 15px !important;
    @media (max-width: 768px) {
      font-size: 1.3rem;
      line-height: 2.5rem;
      padding: 10px 10px !important;
    }
  }

  .lotto-date {
    right: 34px;
    top: 70px;
    font-size: 19px;
    letter-spacing: 0.3px;
    font-weight: 600;
    color: black;
    background-color: #efefef;
    padding: 3px 8px;

    //TABLET
    @media (max-width: 1024px) {
      // top: 2.0rem;
      // font-size: $font-lg;
      // padding-left: 0.9rem;
      right: auto;
      left: 50%;
      top: 54px;
      font-size: 19px;
      letter-spacing: 0.3px;
      font-weight: 600;
      color: black;
      background-color: #efefef;
      padding: 3px 8px;
    }

    //TABLET
    @media (max-width: 768px) {
      // top: 2.0rem;
      // font-size: $font-lg;
      // padding-left: 0.9rem;
      right: auto;
      left: 50%;
      top: 54px;
      font-size: 17px;
      letter-spacing: 0.3px;
      font-weight: 600;
      color: black;
      background-color: #efefef;
      padding: 3px 8px;
    }

    //MOBILE L
    @media (max-width: 425px) {
      top: 54px;
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 600;
      color: black;
      background-color: #efefef;
      padding: 3px 8px;
    }

    //MOBILE M
    @media (max-width: 375px) {
      top: 49px;
      font-size: 14px;
      letter-spacing: 0.3px;
      font-weight: 600;
      color: black;
      background-color: #efefef;
      padding: 3px 8px;
    }

    //MOBILE S
    @media (max-width: 320px) {
      top: 42px;
      font-size: 12px;
      letter-spacing: -0.1px;
      font-weight: 600;
      color: black;
      background-color: #efefef;
      padding: 3px 8px;
    }
  }

  .lotto-payment {
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #00a9a8;
    font-weight: 400;
    color: #000000;
    line-height: 1.5rem;

    //TABLET
    @media (max-width: 1024px) {
      // top: 2.0rem;
      // font-size: $font-lg;
      // padding-left: 0.9rem;
    }

    //MOBILE L
    @media (max-width: 425px) {
    }

    //MOBILE M
    @media (max-width: 425px) {
    }

    //MOBILE S
    @media (max-width: 320px) {
      // top: 42px;
      // font-size: 14px;
      // letter-spacing: -0.1px;
      // font-weight: 600;
      // color: black;
      // background-color: #efefef;
      // padding: 3px 8px;
    }
  }

  .lotto-logo {
    bottom: 18px;
    background-color: rgba(255, 255, 255, 1);
    padding: 15px;
    width: 50%;
    right: 0;
    text-align: center;
    font-size: $font-xl;

    //TABLET
    @media (max-width: 1024px) {
    }

    @media (max-width: 768px) {
    }

    //MOBILE L
    @media (max-width: 425px) {
    }

    //MOBILE M
    @media (max-width: 375px) {
      padding: 10px;
      font-size: $font-lg;
    }

    //MOBILE S
    @media (max-width: 320px) {
      font-size: $font-sm;
    }
  }

  .card-body {
    padding: 0.8rem;
    @media (max-width: 450px) {
      padding: 0.5rem;
    }
  }

  .lotto-price {
    padding: 0px !important;
    line-height: 20px;
    text-align: center;
    @media (max-width: 480px) {
      font-size: 15px !important;
      line-height: 15px;
    }
    @media (max-width: 450px) {
      font-size: 12px !important;
      line-height: 12px;
      padding: 0px !important;
    }

    .font-xss {
      @media (max-width: 480px) {
        font-size: 12px !important;
      }
      @media (max-width: 450px) {
        font-size: 10px !important;
      }
    }
  }

  .lotto-buttons {
    .lotto-button-buy {
      background-color: green !important;
    }
    @media (max-width: 1050px) {
      .font-xss {
        padding: 10px 12px !important;
        border-radius: 12px !important;
      }
    }
    @media (max-width: 790px) {
      .font-xss {
        font-size: 15px !important;
        padding: 5px 10px !important;
        border-radius: 10px !important;
      }
    }
    @media (max-width: 450px) {
      .font-xss {
        font-size: 10px !important;
        line-height: 22px !important;
        padding: 0px 5px !important;
        border-radius: 5px !important;
      }
    }
  }

  .mb-show {
    display: none;
    @media (max-width: 1120px) {
      display: block;
    }
  }

  .mb-hide {
    display: block;
    @media (max-width: 1120px) {
      display: none;
    }
  }

  // @media (max-width: 1120px) {
  //   background-color: #36d876;
  // }

  &.message {
    .lotto-number {
      font-size: $font-xxl;

      @media (max-width: 768px) {
        font-size: $font-lg;
      }
      @media (max-width: 375px) {
        font-size: $font-xs;
        top: 0.3rem;
      }
      @media (max-width: 320px) {
        font-size: $font-xssss;
        top: 0.1rem;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .lotto-logo {
      bottom: 10px;
      @media (max-width: 768px) {
        font-size: $font-lg;
      }
      @media (max-width: 425px) {
        font-size: $font-md;
      }
      @media (max-width: 375px) {
        font-size: $font-xs;
      }
      @media (max-width: 320px) {
        font-size: $font-xsss;
      }
    }
  }
}

.lotto-pane {
  display: block;
  &.hide {
    display: none;
  }
}

.lotto-view-btn {
  cursor: pointer;
}

.cart-wrapper {
  .table-responsive {
    overflow-x: auto !important;
  }
}

.payment-list {
  &.mb-show {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  &.mb-hide {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .lottery-search-result {
    max-width: 475px;
  }
  .table-content {
    .lottery-search-result {
      max-width: 460px;
      margin: 5px;
    }

    tr.data-row {
      td {
        border: none;
      }
    }
  }
}

.mb-show {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}
.mb-hide {
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
}

@media print {
  #root,
  .no-print {
    display: none;
  }
}

/* COUNT DOWN */
@import url("https://fonts.googleapis.com/css?family=Lato");

.payment-qr-content {
  max-width: 1024px;
  margin: auto;
  padding: 20px;

  * {
    box-sizing: border-box;
  }

  h1 {
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
  }

  h3 {
    font-size: 1.2rem;
    span {
      font-weight: 700;
    }
  }

  .qr-image {
    text-align: center;
    margin-top: 10px;
    a {
      margin-top: 10px;
    }
  }

  .countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .countdown-item {
    color: #111;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
  }

  .countdown-item span {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .countdown-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }
}

/* COUNT DOWN */

/* DEALER FORM */
.dealer-form .container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.dealer-form .container > form {
  width: 70%;
  border: 1px solid #dfdfdf;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
}

.dealer-form input {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 25px;
}

.dealer-form pre {
  width: 70%;
  color: #fff;
  font-size: larger;
}

.dealer-form button {
  background: #05b462 !important;
  padding: 5px;
  border-radius: 10px;
  width: 100%;
}

.dealer-form p {
  color: rgb(255, 0, 0);
}

.dealer-form .text {
  margin: 2px;
  padding: 5px;
}

.dealer-form span {
  color: rgb(0, 95, 236);
  cursor: pointer;
}
.bg-customer-menu-ico {
  background: #00a9a8 !important;
  color: #fff;
  font-size: 24px;
}
.dealer-form {
  .container.enter-dealer-code-wrp {
    max-width: 800px;

    .frm-wrp {
      width: 90%;
      padding: 30px;
      background: #fff;
      text-align: center;

      .form-msg {
        margin-top: 25px;
        padding: 25px;
        border-radius: 8px;
        border: 1px solid #f9e154;
        background-color: #faefb1;
      }
    }
    form {
      margin: 0 auto;
      max-width: 500px;
      background: unset;
      input {
        max-width: 300px;
        width: 95%;
        margin-left: 0;
        color: #333 !important;
        padding-left: 15px !important;
        &::placeholder {
          color: rgb(203, 203, 203);
          opacity: 1; /* Firefox */
        }
      }
      button {
        width: 200px;
        color: #fff;
        background: #41b4c3 !important;
      }
    }
  }
}
.lotto-pane-dt.hide {
  display: none;
}

@media screen and (max-width: 480px) {
  // .nav-header .nav-top a .logo-text
  .nav-header .nav-top a {
    font-size: 14px;
    .logo-text {
      font-size: 24px !important;
    }
  }
  .nav-top button {
    font-size: 14px;
    i {
      display: none;
    }
  }
}
.dealer-detail * {
  font-size: 16px !important;
}

.main-content.privacy-page {
  p {
    padding: 8px 15px;
  }
  ol,
  ul {
    padding-left: 30px;
  }
  ol > li {
    list-style: decimal;
  }
  ul > li {
    list-style: disc;
  }
}

.winner-list {
  width: 100%;
  overflow-x: auto;
  font-size: x-small;
}

.delaer-profit-list {
  width: 100%;
  overflow-x: auto;
  font-size: x-small;
}

.number-price-container {
  .number-price-head {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #363636;
    font-weight: bold;
    background-color: #f6f7fc;
    > div {
      flex: 1;
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;

      > p {
        font-size: 12px;
      }
    }
  }
  .number-price-item {
    .number-price-set {
      position: relative;
      display: flex;
      > div {
        flex: 1;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        line-height: 50px;
        height: 50px;
      }

      > div:first-child {
        padding-left: 25px;
      }

      > div.add-row {
        padding-left: 0;
        display: flex;
        justify-content: flex-end;
        line-height: 30px;
        height: 30px;

        span {
          svg {
            height: 30px;
          }
        }
      }
    }
    .number-price-set:first-child {
      padding-left: 0;
    }
  }
}

.period {
  width: 100%;
  border: 2px solid #d5dbdb;
  border-radius: 26px;
  padding: 35px;
  text-align: center;
  margin-top: 35px;
  position: relative;
  > img {
    position: absolute;
    top: -25px;
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

@media screen and (min-width: 480px) {
  #dealer-tab-head {
    position: relative;

    li:last-child {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.cost-table {
  position: relative;
  width: 100%;

  .cost-row {
    display: flex;

    div {
      text-align: center;
    }
  }

  .cost-row.first {
    border-top: 1px solid #00a9a8;
    padding-top: 15px;
    margin-top: 15px;
  }
}
